import React from "react";
import "./P3H.css";
import HospitalImage from "./2docs.png";

const Page3 = () => {
  return (
    <div className="P3H-container">
      <h2 className="P3H-title">Benefits</h2>
      <div className="P3H-bento-grid">
        <div className="P3H-image-container">
          <img
            src={HospitalImage}
            alt="Modern hospital using Krysta.care"
            className="P3H-main-image"
          />
        </div>
        <div className="P3H-benefit-item">
  <h3>Single Page Management</h3>
  <p>Effortlessly manage both walk-in and appointment patients from one unified interface.</p>
</div>

<div className="P3H-benefit-item">
  <h3>Zero Subscription Fee</h3>
  {/* <p>Our earnings are tied directly to your transactions, ensuring we’re always aligned with your success.</p> */}
<p>Our earnings are directly tied to your transactions, ensuring our success is aligned with yours. No upfront costs, no subscription fees.</p>

</div>

<div className="P3H-benefit-item">
  <h3>All-In-One Solution</h3>
  <p>Access a comprehensive suite of features designed to streamline and simplify your operations.</p>
</div>

<div className="P3H-benefit-item">
  <h3>Improve Staff Efficiency</h3>
  <p>Boost productivity and optimize resource management with our intuitive tools.</p>
</div>

<div className="P3H-benefit-item">
  <h3>Increase Patient Satisfaction</h3>
  <p>Enhance the quality of care and build lasting patient loyalty through improved service.</p>
</div>



        <div className="P3H-cta-container">
          <a
            href="https://partner.krysta.care/SignUp"
            className="P3H-cta-button P3H-primary"
          >
            Try for Free
          </a>
          
        </div>
      </div>
    </div>
  );
};

export default Page3;
