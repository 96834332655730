import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="P4H-about-container">
      {/* <h1 className="P4H-heading">Discover Krysta Care</h1> */}

      <p className="P4H-description">
        We Focus on Enhancing Patient Experience
      </p>
      
      <p className="P4H-description">
        Our next-gen appointment module provides patients with real-time confirmation, ensuring a seamless booking experience. Plus, our state-of-the-art digital queue management solution allows patients to track their token status in real-time, enhancing their overall experience.
      </p>
      <p className="P4H-description">
        But that's not all. Each registered hospital receives a unique link, branded with their logo and UI matching your brand theme, that can be shared across multiple platforms to drive direct appointments. Hospitals can also create appointments and token numbers manually.
      </p>
      {/* <p className="P4H-description">
        The best part? It Join us today and take the first step towards transforming your healthcare services.
      </p> */}
    </div>
  );
}

export default AboutUs;
