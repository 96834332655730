import React from "react";
import "./P2H.css";
import KCLP2 from "./P2Himage.jpg"; // Adjust the path according to your project structure

const LandingPage = () => {
  return (
    <div className="P2H-landing-container">
      <div className="P2H-left-section">
        <div className="P2H-image-container">
          {/* <div className="P2H-floating-element P2H-e1"></div>
          <div className="P2H-floating-element P2H-e2"></div>
          <div className="P2H-floating-element P2H-e3"></div> */}
          <img
            src={KCLP2}
            alt="AI-Powered Healthcare Management"
            className="P2H-main-image"
          />
        </div>
      </div>
      <div className="P2H-right-section">
        <div className="P2H-content-wrapper">
          <h1 className="P2H-title" >Why choose us? </h1>
          {/* <p className="P2H-subtitle">Elevate Your Patient Experience</p> */}
          <ul className="P2H-highlight">
            
          

<li>No Server Setup Required</li>
<li>100% Cloud-Based Solution</li>
<li>All-In-One Solution</li>
<li>Improve Staff Efficiency</li>
<li>Enhance Patient Experience </li>
<li>Zero Subscription Fee</li>
<li>Transaction-Based Billing</li>
<li>API available to integrate with your HMS</li>



            {/* <li>
              Performance-Based Commission - Pay only for appointments you get
              through Krysta Care portal for patients
            </li> */}
            
              {/* <a className="linkKC" href="https://krysta.care/" target="blank"> Krysta Care Portal</a> */}
            
          </ul>
          {/* <div className="P2H-cta-container">
            <a href="#" className="P2H-cta-button P2H-primary">
              Get Started
            </a>
            <a href="#" className="P2H-cta-button P2H-secondary">
              Learn More
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
