import React from 'react';
import './P4H.css';

const features = [
  { 
    title: '24/7 Virtual Receptionist', 
    desc: 'Simplify appointment scheduling for your patients, giving them 24/7 access while you stay in full control of every booking' 
  },

  {
    "title": "AI-Powered Appointment Bot",
    "desc": "Enhance user experience with a WhatsApp-themed chatbot to simplify appointment booking, combining ease of use with the power of AI."
  },
  

  {
    "title": "Next-Gen Queue Management",
    "desc": "Eliminate the hassle of paper tokens. Patients can view their queue status in real-time by simply entering their mobile number —no app download needed."
  },
  
  
  { 
    title: 'Flexible Options', 
    desc: 'Multiple booking methods, including QR codes, direct links, and manual entries for Appointment and Walk-In patients.' 
  },

  { 
    title: 'Unified Management Pages', 
    desc: 'Streamline operations with single admin and doctor pages for managing both Walk-In and Appointment patients efficiently.' 
  },
  {
    "title": "No Additional Hardware Required",
    "desc": "Display serving token numbers, hospital videos, and QR codes for new appointments on any TV with browser support. No need to maintain any server."
  },
  
  { 
    title: 'Zero Subscription Fee', 
    desc: 'Our earnings are directly tied to your transactions, ensuring our success is aligned with yours. No upfront costs, no subscription fees.' 
  },
  { 
    title: 'Transaction Fee', 
    desc: 'Get in touch to discover your exclusive transaction fee details.We are committed to providing top-notch support and partnering with you for mutual success.' 
  }
];



const AppointmentQueueSolution = () => (
  <div className="P4H-app" id="target-section">
    <div className="P4H-features">
      {features.map(({ title, desc }) => (
        <div key={title} className="P4H-card">
          <h2>{title}</h2>
          <p>{desc}</p>
        </div>
      ))}
    </div>
  </div>
);

export default AppointmentQueueSolution;