import React from "react";
import LandingPage from "../LandingPage/LandingPage";
import Page2ofHomePage from "../Page2Home/P2H";
import Page3ofHomePage from "../P3H/P3H";
import Page4ofHomePage from "../P4H/P4H";
import AIbotPage from"../AIbot/AIbot";

const Home = () => {
  return (
    <>
    
      <LandingPage/>
      <Page4ofHomePage/>
      <AIbotPage/>
      <Page2ofHomePage/>
      <Page3ofHomePage/>  
     
     

    </>
  );
};

export default Home;
