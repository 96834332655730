import React from 'react';
import './AIbot.css';
import KCLP2 from './AIbotIMG.png'; // Adjust the path according to your project structure
import { Link } from 'react-scroll';

const LandingPage = () => {
  return (
    <div className="landing-container">
      <div className="left-section">
        <div className="content-wrapper">
          <h1 className="title">
          AI-Powered Appointment Bot
          </h1>
          <span className="highlight"><br /></span>
          <p className="subtitle1">Enhance user experience with a 
            WhatsApp-themed chatbot to simplify appointment booking, combining ease of use with the power of AI.</p>
          <div className="cta-container">
            <a href="https://partner.krysta.care/AIChatBotForAppointment?HID=ZABZPdHc1AP9UmUVasIxlH07INl1&phoneCode=%2B968&email=team.krysta.care%40gmail.com" className="cta-button primary">Try Now</a>
            {/* <a href="#" className="cta-button secondary">Learn More</a> */}
            {/* <Link to="target-section" smooth={true} duration={1000} className="cta-button secondary">
              Learn More
            </Link> */}
          </div>

          {/* <div className="stats">
            <div className="stat-item">
              <span className="stat-number">30%</span><br />
              <span className="stat-label">Increase in Patient Satisfaction.</span>
            </div>
            <div className="stat-item">
              <span className="stat-number">40%</span><br />
              <span className="stat-label">Improvement in Staff Efficiency</span>
            </div>
            <div className="stat-item">
              <span className="stat-number">100%</span><br />
              <span className="stat-label">Software based Solution.</span>
            </div>
          </div> */}
        </div>
      </div>
      <div className="right-section">
        <div className="image-container">
          <img src={KCLP2} alt="AI Visualization" className="AIBOT-image" />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
