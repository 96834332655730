import Navbar from "./components/Navbar/Navbar";

import "./App.css";
import Footer from "./components/Footer/Footer";

import { BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./components/Home/Home";

import AboutUs from "./components/AboutUs/AboutUs";




function App() {
 

  return (
    <div className="App">
      <div className="gradient__bg">
      <BrowserRouter>
      <Navbar/>
        
        <Routes>

          <Route exact path='/' element={<Home/>} />
          <Route exact path='/AboutUs' element={<AboutUs/>} />
         
          
          
        </Routes>
        <Footer/>
      
      </BrowserRouter>

      
      </div>
    </div>
  );
}

export default App;
