import React, { useState } from 'react';
import './Navbar.css';
import { FaBars, FaTimes } from 'react-icons/fa';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div>
  <a href="/" className="krysta-link">
    <span className="krysta">krysta.care</span>
  </a>
</div>

      {/* <button className={`navbar-toggle ${isOpen ? 'active' : ''}`} onClick={toggleMenu}>
        {isOpen ? <FaTimes className="icon" /> : <FaBars className="icon" />}
      </button> */}

<button className={`navbar-toggle ${isOpen ? 'active' : ''}`} onClick={toggleMenu}>
  <span className="icon"></span>
</button>

      <ul className={`navbar-menu ${isOpen ? 'active' : ''}`}>
        <li className="navbar-item"><a href="/">Home</a></li>
        <li className="navbar-item"><a href="/AboutUs">About Us</a></li>
        <li className="navbar-item"><a href="https://partner.krysta.care/SignUp">Sign Up</a></li>
        <li className="navbar-item"><a href="https://partner.krysta.care/Login">Login</a></li>
        {/* <li className="navbar-item"><a href="/">Contact Us</a></li> */}


 





      </ul>
    </nav>
  );
}

export default Navbar;
