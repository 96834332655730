import React, { useState, useEffect } from 'react';
import './LandingPage.css';
import KCLP2 from './KCLP2.png'; // Adjust the path according to your project structure
import { Link } from 'react-scroll';

const LandingPage = () => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const subtitleTexts = [
    'Zero Subscription Fee, Transaction-Based Billing - We Grow with You',
    'Enhance Patient Experience Without Replacing Your Existing System',
    'No Need to Arrange Servers – We Handle All Hosting for You',
  
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % subtitleTexts.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [subtitleTexts.length]);

  return (
    <div className="landing-container">
      <div className="left-section">
        <div className="content-wrapper">
        <h1 className="title">
  Next-Generation <br />Appointment and Queue Management Solution
  to Enhance Patient Experience
</h1>

<span className="highlight"></span>
          {subtitleTexts.map((text, index) => (
            <p
              key={index}
              className="subtitle"
              style={{ display: currentIndex === index ? 'block' : 'none' }}
            >
              {text}
            </p>
          ))}


{/* <div className="additional-info">
  <p>It’s absolutely free to join.  </p>
</div> */}

          <div className="cta-container">

          <a href="https://partner.krysta.care/SignUp" className="cta-button primary">Try for Free</a>
          {/* <a href="#" className="cta-button secondary">Learn More</a> */}
          <Link to="target-section" smooth={true} duration={1000} className="cta-button secondary">
  Learn More
</Link>

          </div>

          {/* <div className="stats">
            <div className="stat-item">
              <span className="stat-number">30%</span><br/>
              <span className="stat-label">Increase in Patient Satisfaction.</span>
            </div>
            <div className="stat-item">
              <span className="stat-number">40%</span><br/>
              <span className="stat-label">Improvement in Staff Efficiency</span>
            </div>
            <div className="stat-item">
              <span className="stat-number">100%</span><br/>
              <span className="stat-label"> Software based Solution.</span>
            </div>
  
          </div> */}


          
          
        </div>
      </div>
      <div className="right-section">
        <div className="image-container">
          <div className="floating-element e1"></div>
          <div className="floating-element e2"></div>
          <div className="floating-element e3"></div>
          <img src={KCLP2} alt="AI Visualization" className="main-image" />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
