import React from "react";
import { Link } from 'react-router-dom';
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">

      <div className="footer-container">
        
      <a href="/" className="krysta-link">
    <span className="krystaFooter">krysta.care</span>
  </a>



        <div className="footer-contact">

          <h3>Contact Us</h3>


          {/* <p>P.O Box: 611. Postal code: 111 </p>
          <p> Opposite Indian School - Al Ghubra </p>
          <p>North Ghobra, Muscat, Sultanate of Oman</p>
          <p>
            Phone: <a href="tel:+96824614272">(+968)24614272</a>
          </p> */}
          <p>
            {/* Email:{" "} */}
            <a href="mailto:team@krysta.care">team@krysta.care</a>
          </p>



        </div>
        <div className="footer-social">
          <h3>Follow Us</h3>
          <ul>
            <li>
              <a href="#" className="social-link">
                Facebook
              </a>
            </li>
            <li>
              <a href="#" className="social-link">
                Twitter
              </a>
            </li>
            <li>
              <a href="#" className="social-link">
                Instagram
              </a>
            </li>
            <li>
              <a href="#" className="social-link">
                LinkedIn
              </a>
            </li>
            <li>
              {/* <a href="/Admin" className="social-link ">
                Login
              </a> */}
            </li>

            {/* <li>
              <a href="#" className="social-link ">
                Back to Top
              </a>
            </li> */}
            
          </ul>
        </div>

        <div className="footer-social">
          <h3>Useful Links</h3>
          <ul>
            
            <li>
              <a href="https://partner.krysta.care/SignUp" className="social-link ">
                Sign Up
              </a>
            </li>

            <li>
              <a href="https://partner.krysta.care/Login" className="social-link ">
                Login
              </a>
            </li>

            <li>
              <Link to="/AboutUs" className="social-link ">
               Learn More
              </Link>
            </li>

            <li>
              <a href="#" className="social-link ">
                Back to Top
              </a>
            </li>
            
          </ul>
        </div>

        
      </div>
    </footer>
  );
};

export default Footer;
